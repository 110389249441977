import React from 'react'
import * as Tabs from '@radix-ui/react-tabs'
import { Collapse } from 'ui'
import { Helmet } from 'react-helmet'

import Properties from './components/Properties'
import IconCard from './components/IconCard'
import InfoCard from './components/InfoCard'
import RateCard from './components/RateCard'
import ContactForm from './components/ContactForm'

import differencials from './data/our-differencials'

import virtualConinsa from './assets/images/virtual-coninsa.png'

import heroBannerUrl from './assets/images/hero-banner.png'
import heroBannerImageUrl from './assets/images/hero-mobile-image.jpg'
import businessBannerUrl from './assets/images/expand-business.jpg'
import businessImageUrl from './assets/images/expand-business-mobile.jpg'

import invoiceIcon from './assets/icons/invoice.svg'
import contractIcon from './assets/icons/contract.svg'
import certificateIcon from './assets/icons/certificate.svg'
import webIcon from './assets/icons/web.svg'
import whatsappIcon from './assets/icons/whatsapp.svg'
import phoneIcon from './assets/icons/phone.svg'
import regionPhone from './assets/icons/alternative-phone.svg'
import regionCellPhone from './assets/icons/cell-phone.svg'
import searchIcon from './assets/icons/search.svg'
import formIcon from './assets/icons/form.svg'
import presentIcon from './assets/icons/present.svg'
import FloatingWhatsappBtn from '../../modules/home/components/FloatingWhatsappBtn.js'

import '../../radix-ui/tabs.css'
import './style.css'

function LeasingPropertyPage() {
  return (
    <div>
      <Helmet>
        <title>Arrendar un inmueble | Coninsa</title>
        <meta name="description" content="Encontramos el inmueble que se adapte a los requerimientos de tu compañía o emprendimiento con bodegas, locales, oficinas o consultorios." />
        <link rel="canonical" href="https://www.coninsa.co/personas/arrendar-un-inmueble/" />
      </Helmet>
      <FloatingWhatsappBtn path='https://api.whatsapp.com/send?phone=573123636333&text=Hola!' />
      <div
        className='hero hero--right-content bg-coninsa-green-300'
        style={{ backgroundImage: `url(${heroBannerUrl})` }}
      >
        <div className='hero__container container'>
          <img className='hero__image' src={heroBannerImageUrl} />

          <div className='hero__content w-1/2!'>
            <h1 className='hero__title font-heading leading-9'>
              <span>¿Necesitas</span>
              <span>Arrendar</span>
              <span>un inmueble?</span>
            </h1>

            <div className='hero__description'>
              <p className='font-medium text-lg text-white'>
                ¡Encuéntralo con nosotros!
              </p>
              <p className='font-heading text-lg text-white'>
                Tenemos un portafolio de más de
              </p>
              <p className='font-heading font-medium text-2xl text-coninsa-blue-900'>
                2.000 inmuebles.
              </p>
            </div>
          </div>
        </div>
      </div>

      <Properties />

      <div className='relative pb-12 bg-coninsa-gray-300'>
        <div className='bg-coninsa-blue-900 absolute bottom-0 left-0 right-0 h-52' />
        <div className='container md:rounded-2xl overflow-hidden'>
          <div
            className='hero-compact bg-coninsa-green-300 z-10 relative'
            style={{ backgroundImage: `url(${businessBannerUrl})` }}
          >
            <div className='hero-compact__container container relative'>
              <img className='hero-compact__image' src={businessImageUrl} />
              <div className='hero-compact__content'>
                <h1 className='hero-compact__title font-heading leading-9'>
                  <span>¿Estás buscando</span>
                  <span>Expandir</span>
                  <span>tu negocio?</span>
                </h1>

                <div className='hero-compact__description'>
                  <p className='font-medium text-lg text-white'>
                    Encontramos el inmueble que se adapte a los requerimientos de tu
                    compañía o emprendimiento con
                  </p>
                  <p className='font-heading font-medium text-lg text-coninsa-green-500'>
                    bodegas, locales, oficinas o consultorios.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div className='bg-coninsa-blue-900 pb-8 md:pb-16'>
          <div className='container'>
            <h3 className='font-heading text-white text-3xl md:text-5xl font-extrabold text-center mb-1 mr-20 md:mr-44'>
              Nuestros
            </h3>
            <div className='text-center mb-8 md:mb-12'>
              <h3
                className='font-heading bg-coninsa-green-300 text-3xl md:text-5xl font-extrabold px-4 py-2 md:px-8 md:py-4 rounded-md md:rounded-lg
                  shadow-[0px_3px_6px_#00000029] text-center relative text-white inline-block'
              >
                diferenciales
              </h3>
              <span
                className='absolute block w-0 h-0 border-t-[24px] border-t-transparent border-r-[22px] md:border-r-[32px]
                border-r-transparent border-l-transparent border-b-[22px] md:border-b-[26px]
                border-b-coninsa-green-300 border translate-x-44 md:translate-x-[650px] rotate-180'
              />
            </div>

            <div className='grid grid-cols-2 md:grid-cols-3 gap-4 px-5 md:px-0'>
              {differencials.map(item => (
                <IconCard icon={item?.icon}>
                  <p
                    className='text-sm leading-[18px] md:leading-5 md:text-base'
                    dangerouslySetInnerHTML={{ __html: item?.text }}
                  />
                </IconCard>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className='px-5 md:px-0 md:container py-16 md:pt-24 md:pb-28'>
        <div className='flex-col md:grid md:grid-cols-[65%_35%] md:gap-8'>
          <div>
            <img alt='virtual-coninsa' src={virtualConinsa} />
          </div>
          <div>
            <div className='mb-5'>
              <h4 className='font-extrabold leading-7 px-16 md:px-0 md:leading-6 text-coninsa-green-300 text-3xl md:text-4xl mb-3 md:mb-5'>
                <span className='text-3xl text-coninsa-blue-900 font-light'>
                  Disfruta de{' '}
                </span>{' '}
                <br /> MI CONINSA VIRTUAL
              </h4>
              <p className='font-light text-sm md:text-base'>
                Una plataforma <span className='font-semibold'>exclusiva</span> de
                nuestra Compañía donde podrás realizar consultas y transacciones,
                como:
              </p>
            </div>
            <div>
              <div className='flex gap-1 items-center'>
                <img alt='invoice-icon' src={invoiceIcon} />
                <p className='font-normal leading-[18px] text-sm md:text-base text-coninsa-blue-900'>
                  Consulta y <br />{' '}
                  <span className='font-semibold'>pago de facturas</span>
                </p>
              </div>

              <div className='flex gap-1 items-center'>
                <img alt='invoice-icon' src={contractIcon} />
                <p className='font-normal leading-[18px] text-sm md:text-base text-coninsa-blue-900'>
                  Tus contratos <br />{' '}
                  <span className='font-semibold'>asociados</span>
                </p>
              </div>

              <div className='flex gap-1 items-center'>
                <img alt='invoice-icon' src={certificateIcon} />
                <p className='font-normal leading-[18px] text-sm md:text-base text-coninsa-blue-900'>
                  Certificados y <br />{' '}
                  <span className='font-semibold'>constancias</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='bg-coninsa-green-300 border-b-2 border-coninsa-green-500 border-solid pb-4 px-5 md:px-0 md:pb-0'>
        <div className='md:container transform -translate-y-12'>
          <h2 className='bg-coninsa-blue-900 font-display font-extralight text-white max-w-sm text-2xl md:text-3xl p-5 md:p-10 rounded-xl uppercase'>
            Conoce más{' '}
            <span className='font-medium text-3xl md:text-[40px] text-coninsa-green-300'>
              información
            </span>
            <br /> sobre arrendamientos
          </h2>
        </div>
      </div>

      <div className='container transform -translate-y-12'>
        <Tabs.Root className='co-tabs' defaultValue='tab-1'>
          <Tabs.List className='co-tabs-list' aria-label='Manage your account'>
            <Tabs.Trigger className='co-tabs-trigger' value='tab-1'>
              Cómo arrendar
            </Tabs.Trigger>
            <Tabs.Trigger className='co-tabs-trigger' value='tab-2'>
              Estudio digital
            </Tabs.Trigger>
            <Tabs.Trigger className='co-tabs-trigger' value='tab-3'>
              ¡Ten esto en cuenta!
            </Tabs.Trigger>
            <Tabs.Trigger className='co-tabs-trigger' value='tab-4'>
              Conoce nuestras tarifas
            </Tabs.Trigger>
          </Tabs.List>

          <Tabs.Content className='co-tabs-content' value='tab-1'>
            <div className='px-5 md:px-0 md:container'>
              <div className='mb-4 md:mb-6'>
                <h4 className='font-display text-[22px] leading-6 md:text-3xl md:leading-normal text-coninsa-blue-900 font-normal mb-3'>
                  ¿Cómo arrendar un inmueble con nosotros?
                </h4>
                <p className='font-normal text-sm leading-4 md:leading-normal md:text-base'>
                  Es tan sencillo como{' '}
                  <span className='font-semibold'>soñar y construir</span> bienestar
                  para el futuro.
                </p>
              </div>

              <div className='md:px-40'>
                <div className='md:flex md:justify-center mb-16'>
                  <div className='mx-5 md:mx-0'>
                    <h3
                      className='bg-coninsa-blue-900 font-display text-white font-extralight px-4 py-2 md:px-8 md:py-4 rounded-lg
                      shadow-[0px_3px_6px_#00000029] text-xl md:text-[28px] uppercase text-center relative'
                    >
                      ¡Te lo contamos{' '}
                      <span className='font-semibold'>en sólo 4 pasos!</span>
                    </h3>
                    <span
                      className='absolute w-0 h-0 border-t-[24px] border-t-transparent border-r-[22px] md:border-r-[32px]
                      border-r-transparent border-l-transparent border-b-[22px] md:border-b-[26px]
                      border-b-coninsa-blue-900 border translate-x-32 md:translate-x-80 rotate-180'
                    />
                  </div>
                </div>

                <div className='mb-14'>
                  <div className='mb-16'>
                    <div className='border-b-[3px] border-solid border-x-coninsa-gray-300 w-full relative mb-5 md:mb-7'>
                      <h4
                        className='absolute bg-coninsa-green-400 font-bold px-5 py-1 text-white uppercase rounded-[20px]
                        shadow-[0px_3px_6px_#00000029] text-sm md:text-lg -top-4'
                      >
                        Paso 1
                      </h4>
                    </div>
                    <p className='text-sm md:text-lg'>
                      Encuentra <span className='font-semibold'>tu inmueble</span> de
                      comercio o vivienda mediante:
                    </p>
                  </div>

                  <div className='px-2 md:px-12'>
                    <div className='block md:flex gap-4 justify-between mb-9'>
                      <InfoCard icon={webIcon}>
                        <div className='pl-14 md:pl-10 pt-8'>
                          <h4 className='font-medium text-coninsa-blue-900 text-base md:text-lg leading-5 mb-4 md:mb-5'>
                            Nuestro <br />{' '}
                            <span className='font-bold'>Portal Web</span>
                          </h4>
                          <a
                            href='https://busquedas.coninsa.co/inmuebles/arriendo'
                            className='border border-solid border-coninsa-green-500 font-bold px-6 md:px-8 py-2 rounded-lg
                            text-coninsa-green-500 text-xs md:text-sm uppercase hover:bg-coninsa-green-500 hover:text-white'
                          >
                            Buscar un inmueble
                          </a>
                        </div>
                      </InfoCard>

                      <InfoCard icon={whatsappIcon}>
                        <div className='pl-14 md:pl-10 pt-8'>
                          <h4 className='font-medium text-coninsa-blue-900 text-base md:text-lg leading-5 mb-4 md:mb-5'>
                            Nuestra asistente virtual <br />{' '}
                            <span className='font-bold'>Chatbot Coninsa</span>
                          </h4>
                          <a
                            href='https://api.whatsapp.com/send?phone=573123636333&text=Hola!'
                            className='border border-solid border-coninsa-green-500 font-bold px-6 md:px-8 py-2 rounded-lg
                            text-coninsa-green-500 text-xs md:text-sm uppercase hover:bg-coninsa-green-500 hover:text-white'
                          >
                            hablar con coninsa
                          </a>
                        </div>
                      </InfoCard>
                    </div>

                    <div className='mb-6'>
                      <InfoCard icon={phoneIcon}>
                        <div className='pl-14 md:pl-10 pt-8'>
                          <h4 className='font-medium text-coninsa-blue-900 text-base md:text-lg leading-5 mb-4 md:mb-5'>
                            A través de nuestras <br />{' '}
                            <span className='font-bold'>Líneas telefónicas</span>
                          </h4>
                          <div className='block md:grid grid-cols-3 gap-4'>
                            <div className='mb-3 md:mb-0'>
                              <h5 className='font-display font-medium text-coninsa-blue-900 mb-1 text-base md:text-lg uppercase'>
                                Antioquia
                              </h5>
                              <div className='flex gap-3'>
                                <img alt='phone' src={regionPhone} />
                                <p className='font-semibold text-sm md:text-base'>
                                  (604) 200 44 24
                                </p>
                              </div>
                              <div className='flex gap-3'>
                                <img alt='cell-phone' src={regionCellPhone} />
                                <p className='text-sm md:text-base'>
                                  (604) 200 44 24
                                </p>
                              </div>
                            </div>

                            <div className='mb-3 md:mb-0'>
                              <h5 className='font-display font-medium text-coninsa-blue-900 mb-1 text-base md:text-lg uppercase'>
                                Bogotá
                              </h5>
                              <div className='flex gap-3'>
                                <img alt='phone' src={regionPhone} />
                                <p className='font-semibold text-sm md:text-base'>
                                  (601) 915 84 41
                                </p>
                              </div>
                              <div className='flex gap-3'>
                                <img alt='cell-phone' src={regionCellPhone} />
                                <p className='text-sm md:text-base'>
                                  (601) 915 84 41
                                </p>
                              </div>
                            </div>

                            <div>
                              <h5 className='font-display font-medium text-coninsa-blue-900 mb-1 text-base md:text-lg uppercase'>
                                Costa Atlántica
                              </h5>
                              <div className='flex gap-3'>
                                <img alt='phone' src={regionPhone} />
                                <p className='font-semibold text-sm md:text-base'>
                                  (605) 331 92 19
                                </p>
                              </div>
                              <div className='flex gap-3'>
                                <img alt='cell-phone' src={regionCellPhone} />
                                <p className='text-sm md:text-base'>
                                  (605) 331 92 19
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </InfoCard>
                    </div>

                    <div className='rounded-lg px-4 md:px-8 pb-4 pt-6 shadow-[0px_3px_6px_#00000029] mb-6'>
                      <Collapse variant='leasing'>
                        <Collapse.Item
                          title='Información de nuestras sedes'
                          index={1}
                          icons={['fa-chevron-down', 'fa-chevron-up']}
                        >
                          <div className='border border-solid border-x-coninsa-gray-300 relative my-4 md:my-6' />
                          <div className='block md:grid grid-cols-[65%_35%] gap-4'>
                            <div>
                              <h5 className='font-display font-medium text-coninsa-blue-900 mb-1 text-base md:text-lg uppercase'>
                                Antioquia
                              </h5>
                              <div className='grid grid-cols-2 gap-2'>
                                <p className='leading-5 text-sm md:text-md'>
                                  <span className='font-bold'>Laureles</span> <br />{' '}
                                  Calle 33 A # 76 - 22
                                </p>

                                <p className='leading-5 text-sm md:text-md'>
                                  <span className='font-bold'>Envigado</span> <br />{' '}
                                  Carrera 27 # 35 Sur - 180 Terracina Plaza, Local
                                  217
                                </p>

                                <p className='leading-5 text-sm md:text-md'>
                                  <span className='font-bold'>Poblado</span> <br />{' '}
                                  Carrera 39 # 5 A - 95 Ed. Avantgarde
                                </p>

                                <p className='leading-5 text-sm md:text-md'>
                                  <span className='font-bold'>Sabaneta</span> <br />{' '}
                                  Calle 69 Sur # 45 - 43 Mall Sabaneta
                                </p>

                                <p className='leading-5 text-sm md:text-md'>
                                  <span className='font-bold'>Centro</span> <br />{' '}
                                  Calle 55 # 45 - 55 La Candelaria
                                </p>

                                <p className='leading-5 text-sm md:text-md'>
                                  <span className='font-bold'>Laureles</span> <br />{' '}
                                  Calle 33A #76-22
                                </p>

                                <p className='leading-5 text-sm md:text-md'>
                                  <span className='font-bold'>Rionegro</span> <br />{' '}
                                  Km 8.5, Vía Club Llanogrande Mall Complex
                                </p>
                              </div>
                            </div>

                            <div>
                              <div className='mb-5'>
                                <h5 className='font-display font-medium text-coninsa-blue-900 mb-1 text-base md:text-lg uppercase'>
                                  Bogotá
                                </h5>
                                <p className='leading-5 text-sm md:text-md'>
                                  <span className='font-bold'>Santa Barbara</span>{' '}
                                  <br /> Carrera 19 # 114 - 65 Piso 6
                                </p>
                              </div>

                              <div>
                                <h5 className='font-display font-medium text-coninsa-blue-900 mb-1 text-base md:text-lg uppercase'>
                                  Barranquilla
                                </h5>
                                <p className='leading-5 text-sm md:text-md'>
                                  <span className='font-bold'>Calle 93</span> <br />{' '}
                                  Calle 93 # 47 - 53
                                </p>
                              </div>
                            </div>
                          </div>
                        </Collapse.Item>
                      </Collapse>
                    </div>

                    <div className='rounded-lg px-4 md:px-8 pb-4 pt-6 shadow-[0px_3px_6px_#00000029]'>
                      <Collapse variant='leasing'>
                        <Collapse.Item
                          title='Conoce nuestros horarios en sedes arrendamientos'
                          index={2}
                          icons={['fa-chevron-down', 'fa-chevron-up']}
                        >
                          <div className='border border-solid border-x-coninsa-gray-300 relative my-4 md:my-6' />
                          <div className='grid grid-cols-2 md:grid-cols-3 gap-4'>
                            <div>
                              <h5 className='font-display font-medium text-coninsa-blue-900 mb-1 text-base md:text-lg uppercase'>
                                Antioquia
                              </h5>

                              <p className='leading-5 mb-2 text-sm'>
                                <span className='font-bold'>Lunes a viernes:</span>{' '}
                                <br /> 7:30 a.m. a 5:30 p.m.
                              </p>

                              <p className='leading-5 text-sm'>
                                <span className='font-bold'>Sábados:</span> <br />{' '}
                                8:00 a.m. a 12:30 p.m.
                              </p>
                            </div>

                            <div>
                              <h5 className='font-display font-medium text-coninsa-blue-900 mb-1 text-base md:text-lg uppercase'>
                                Bogotá
                              </h5>

                              <p className='leading-5 mb-2 text-sm'>
                                <span className='font-bold'>Lunes a viernes:</span>{' '}
                                <br /> 7:00 a.m. a 5:30 p.m.
                              </p>

                              <p className='leading-5 text-sm'>
                                <span className='font-bold'>Sábados:</span> <br />{' '}
                                8:00 a.m. a 12:00 p.m.
                              </p>
                            </div>

                            <div>
                              <h5 className='font-display font-medium text-coninsa-blue-900 mb-1 text-base md:text-lg uppercase'>
                                Barranquilla
                              </h5>

                              <p className='leading-5 mb-2 text-sm'>
                                <span className='font-bold'>Lunes a viernes:</span>{' '}
                                <br /> 7:00 a.m. a 5:30 p.m.
                              </p>

                              <p className='leading-5 text-sm'>
                                <span className='font-bold'>Sábados:</span> <br />{' '}
                                8:00 a.m. a 4:00 p.m.
                              </p>
                            </div>
                          </div>
                        </Collapse.Item>
                      </Collapse>
                    </div>
                  </div>
                </div>

                <div className='mb-14'>
                  <div className='border-b-[3px] border-solid border-x-coninsa-gray-300 w-full relative mb-5 md:mb-7'>
                    <h4
                      className='absolute bg-coninsa-green-400 font-bold px-5 py-1 text-white uppercase rounded-[20px]
                      shadow-[0px_3px_6px_#00000029] text-sm md:text-lg -top-4'
                    >
                      Paso 2
                    </h4>
                  </div>
                  <p className='text-sm md:text-lg'>
                    <span className='font-semibold'>
                      Conoce el inmueble mediante las fotografías,{' '}
                    </span>
                    también puedes coordinar un espacio para visitar los inmuebles de
                    interés.
                  </p>
                </div>

                <div className='mb-14'>
                  <div className='border-b-[3px] border-solid border-x-coninsa-gray-300 w-full relative mb-5 md:mb-7'>
                    <h4
                      className='absolute bg-coninsa-green-400 font-bold px-5 py-1 text-white uppercase rounded-[20px]
                      shadow-[0px_3px_6px_#00000029] text-sm md:text-lg -top-4'
                    >
                      Paso 3
                    </h4>
                  </div>
                  <div className='block md:flex gap-5 items-center mb-10 rounded-lg'>
                    <p className='text-sm md:text-lg mb-5 md:mb-0'>
                      Realiza la solicitud de arrendamiento
                      <span className='font-semibold'>
                        {' '}
                        ingresando a la opción estudio digital.
                      </span>
                    </p>

                    <a
                      href='https://www.coninsa.co/estudio-digital/solicitud'
                      target='_blank'
                      className='cursor-pointer font-bold flex-shrink-0 text-xs md:text-sm border border-coninsa-green-300 bg-coninsa-green-300 inline-block
                      py-[10px] w-full md:w-auto text-center px-8 rounded-md uppercase text-white hover:bg-coninsa-green-500 hover:border-coninsa-green-500'
                      rel='noreferrer'
                    >
                      Realizar mi estudio digital
                    </a>
                  </div>
                </div>

                <div className='mb-8 md:mb-16'>
                  <div className='border-b-[3px] border-solid border-x-coninsa-gray-300 w-full relative mb-5 md:mb-7'>
                    <h4
                      className='absolute bg-coninsa-green-400 font-bold px-5 py-1 text-white uppercase rounded-[20px]
                      shadow-[0px_3px_6px_#00000029] text-sm md:text-lg -top-4'
                    >
                      Paso 4
                    </h4>
                  </div>
                  <p className='text-sm md:text-lg'>
                    Si tu solicitud sale aprobada, podrás continuar con el proceso de
                    arrendamiento de forma virtual, además tu contrato de
                    arrendamiento lo haces con
                    <span className='font-semibold'>
                      {' '}
                      firma electrónica mediante nuestras plataformas web.
                    </span>
                  </p>
                </div>

                <p
                  className='bg-coninsa-blue-900 rounded-lg font-medium leading-5 md:leading-[22px] p-5 md:px-14 md:py-7
                text-white text-center text-sm md:text-lg'
                >
                  <span className='font-bold'>
                    Así que te invitamos a conocer todas las posibilidades que
                    tenemos para ti.
                  </span>{' '}
                  <br />
                  No necesitas más que saber bien tus necesidades, porque de
                  asesorarte y encontrarte el mejor espacio nos encargamos nosotros.
                </p>
              </div>
            </div>
          </Tabs.Content>

          <Tabs.Content className='co-tabs-content' value='tab-2'>
            <div className='px-5 container md:px-0'>
              <div className='mb-14 md:mb-12'>
                <h4 className='font-display text-[22px] text-coninsa-blue-900 font-normal leading-6 mb-3 md:leading-normal md:text-3xl'>
                  ¿Cómo hacer una Solicitud de Arriendo y qué documentación
                  necesitas?
                </h4>
                <p className='font-normal leading-5 text-sm md:leading-normal md:text-base'>
                  Realiza el estudio de arrendamiento
                  <span className='font-semibold'> 100% gratis y digital.</span>
                </p>
              </div>

              <div className='px-0 md:px-40'>
                <div className=''>
                  <div className='block md:grid grid-cols-2 gap-16 mb-7 md:mb-9'>
                    <InfoCard icon={searchIcon}>
                      <div className='pl-14 pt-8 md:pl-10'>
                        <h4 className='font-medium text-coninsa-blue-900 text-base md:text-lg leading-[18px] md:leading-5 mb-3 md:mb-5'>
                          Buscar el inmueble <br />{' '}
                          <span className='font-bold'> de tu preferencia</span>
                        </h4>
                        <p className='leading-[18px] md:pb-[17px] text-sm md:leading-5 md:text-base'>
                          En la ficha del inmueble{' '}
                          <span className='font-semibold'>
                            encontrarás un destacado{' '}
                          </span>{' '}
                          para comenzar tu estudio digital.
                        </p>
                      </div>
                    </InfoCard>

                    <InfoCard icon={formIcon}>
                      <div className='pl-14 pt-8 md:pl-10'>
                        <h4 className='font-medium text-coninsa-blue-900 text-base md:text-lg leading-[18px] md:leading-5 mb-3 md:mb-4'>
                          Diligencia el formulario <br />{' '}
                          <span className='font-bold'>
                            {' '}
                            y adjunta tus documentos
                          </span>
                        </h4>
                        <p className='leading-[18px] text-sm md:leading-5 md:text-base'>
                          Te llegará un correo de confirmación para continuar con
                          <span className='font-semibold'>
                            {' '}
                            el proceso con uno de nuestros consultores inmobiliarios.
                          </span>
                        </p>
                      </div>
                    </InfoCard>
                  </div>

                  <div className='bg-coninsa-blue-900 block md:flex gap-4 items-center mb-8 md:mb-10 opacity-100 p-6 rounded-lg'>
                    <p className='text-base text-white mb-5 md:mb-0 md:text-lg'>
                      <span className='font-bold'>
                        Si no tienes un inmueble definido,
                      </span>
                      puedes adelantar tu solicitud de arrendamiento para agilizar
                      los procesos.
                    </p>
                    <a
                      href='https://www.coninsa.co/estudio-digital/'
                      target='_blank'
                      className='cursor-pointer font-bold flex-shrink-0 text-sm border border-white inline-block
                        py-[10px] px-6 md:px-8 rounded-md uppercase text-white hover:bg-coninsa-green-300 hover:border-coninsa-green-300'
                      rel='noreferrer'
                    >
                      Realizar mi estudio digital
                    </a>
                  </div>

                  <div className='rounded-lg px-4 md:px-8 pb-4 pt-6 shadow-[0px_3px_6px_#00000029] mb-6'>
                    <Collapse variant='leasing'>
                      <Collapse.Item
                        title='Documentos para persona natural'
                        index={1}
                        icons={['fa-chevron-down', 'fa-chevron-up']}
                      >
                        <p className='text-sm md:text-base'>
                          Arrendatario o Coarrendatario
                        </p>
                        <div className='border border-solid border-x-coninsa-gray-300 relative my-4 md:my-6' />
                        <ul
                          className='list-disc list-outside ml-4 md:ml-0 md:list-inside space-y-2 text-sm
                          md:text-base marker:text-coninsa-green-300'
                        >
                          <li>Formularios diligenciados</li>
                          <li>Fotocopia de documento de identidad</li>
                          <li>Si eres empleado, los certificados laborales</li>
                          <li>
                            Si eres independiente, soporte de la actividad económica
                            que te genera los ingresos
                          </li>
                          <li>Extractos bancarios de los últimos tres meses</li>
                          <li>RUT para el arrendatario</li>
                          <li>
                            Documentos adicionales que requiera la aseguradora y/o
                            afianzadora
                          </li>
                        </ul>
                      </Collapse.Item>
                    </Collapse>
                  </div>

                  <div className='rounded-lg px-4 md:px-8 pb-4 pt-6 shadow-[0px_3px_6px_#00000029] mb-6'>
                    <Collapse variant='leasing'>
                      <Collapse.Item
                        title='Documentos para persona jurídica'
                        index={2}
                        icons={['fa-chevron-down', 'fa-chevron-up']}
                      >
                        <p className='text-sm md:text-base'>
                          Arrendatario o Coarrendatario
                        </p>
                        <div className='border border-solid border-x-coninsa-gray-300 relative my-4 md:my-6' />
                        <ul
                          className='list-disc list-outside ml-4 md:ml-0 md:list-inside space-y-2 text-sm md:text-base
                          marker:text-coninsa-green-300'
                        >
                          <li>Formularios diligenciados</li>
                          <li>Cámara de comercio no mayor a 30 días</li>
                          <li>Documento de identidad del Representante Legal</li>
                          <li>Extractos bancarios de los últimos tres meses</li>
                          <li>Estados financieros de los dos últimos años</li>
                          <li>Fotocopia del RUT</li>
                          <li>Fotocopia de la última declaración de renta</li>
                          <li>
                            Documentos adicionales que requiera la aseguradora y/o
                            afianzadora
                          </li>
                        </ul>
                      </Collapse.Item>
                    </Collapse>
                  </div>

                  <div className='rounded-lg px-4 md:px-8 pb-4 pt-6 shadow-[0px_3px_6px_#00000029]'>
                    <Collapse variant='leasing'>
                      <Collapse.Item
                        title='Documentos para presentar Afianzadora o Aseguradora'
                        index={3}
                        icons={['fa-chevron-down', 'fa-chevron-up']}
                      >
                        <div className='border border-solid border-x-coninsa-gray-300 relative my-4 md:my-6' />
                        <ul
                          className='list-disc list-outside ml-4 md:ml-0 md:list-inside space-y-2 text-sm md:text-base
                          marker:text-coninsa-green-300'
                        >
                          <li>
                            Consignación original por el valor del estudio de la
                            solicitud de crédito.
                          </li>
                          <li>
                            Certificado original de tradición y libertad de la
                            propiedad raíz, no mayor a 30 días de expedición.
                          </li>
                        </ul>
                      </Collapse.Item>
                    </Collapse>
                  </div>
                </div>
              </div>
            </div>
          </Tabs.Content>

          <Tabs.Content className='co-tabs-content' value='tab-3'>
            <div className='px-5 md:px-0 md:container'>
              <div className='mb-5 md:mb-7'>
                <h4 className='font-display text-[22px] leading-6 md:leading-normal md:text-3xl text-coninsa-blue-900 font-normal mb-3'>
                  ¿Qué debes tener en cuenta para arrendar un inmueble?
                </h4>
                <p className='font-normal leading-[18px] text-sm md:leading-5 md:text-base'>
                  Para nosotros es importante que siempre tengas claridad sobre los
                  procesos que hacemos juntos.
                </p>
              </div>

              <div className='px-0 md:px-40'>
                <div className='mb-10'>
                  <h5 className='font-bold text-coninsa-blue-900 text-base md:text-lg mb-4 md:mb-5'>
                    ¡Ten en cuenta éstas consideraciones!
                  </h5>
                  <ul className='leading-[18px] list-disc list-outside text-sm md:leading-5 md:text-base ml-5 space-y-4 marker:text-coninsa-green-300'>
                    <li>
                      La solicitud de arrendamiento deberás presentarla{' '}
                      <span className='font-bold'>
                        {' '}
                        junto con la de tu coarrendatario
                      </span>
                      .
                    </li>
                    <li>
                      La agilidad del estudio dependerá de la
                      <span className='font-bold'>
                        {' '}
                        claridad de la información que nos suministres
                      </span>
                      , y por supuesto, de que cumplas con todos los requisitos.
                    </li>
                    <li>
                      Si no tienes un inmueble definido aún, puedes comenzar tu
                      solicitud de arrendamiento y te ayudaremos a encontrar el mejor
                      inmueble para ti.
                    </li>
                    <li>
                      La habilitación del gas y los servicios especiales deberás
                      solicitarla directamente a las entidades de servicios
                      domiciliarios.
                    </li>
                    <li>
                      Debes tener en cuenta que, por seguridad,{' '}
                      <span className='font-bold'>
                        {' '}
                        no recibimos dinero en efectivo{' '}
                      </span>{' '}
                      dentro de nuestras oficinas.
                    </li>
                    <li>
                      Además, te recomendamos tener mucho cuidado al momento de la
                      mudanza, para que evites daños tanto en tus muebles como en los
                      de la propiedad que ocuparás.
                      <span className='font-bold'> Y una regla básica: </span>
                      cambia las claves de las cerraduras de la puerta principal del
                      inmueble.
                      <a
                        href='#'
                        target='_blank'
                        className='font-bold text-coninsa-green-500 underline'
                      >
                        {' '}
                        Visita nuestro Blog y lee el artículo
                      </a>
                      .
                    </li>
                  </ul>
                </div>

                <div>
                  <h5 className='font-bold text-coninsa-blue-900 text-base leading-5 md:leading-normal md:text-lg mb-4 md:mb-5'>
                    En la consecución, trámite de la solicitud y entrega del
                    inmueble:
                  </h5>
                  <ul className='leading-[18px] md:leading-5 list-disc list-outside ml-5 space-y-4 text-sm md:text-base marker:text-coninsa-green-300'>
                    <li>
                      La solicitud de arrendamiento deberá ser presentada
                      <span className='font-bold'>
                        {' '}
                        por la persona que ocupará el inmueble
                      </span>
                      , con su respectivo Coarrendatario.
                    </li>
                    <li>
                      El canon de Arrendamiento no deberá superar el{' '}
                      <span className='font-bold'> 40% </span>
                      de los ingresos percibidos por el Arrendatario y el{' '}
                      <span className='font-bold'> 50% </span> para el
                      Coarrendatario.
                    </li>
                    <li>
                      Nuestro asesor coordinará contigo la cita para la elaboración
                      del inventario del inmueble, documento que hará parte integral
                      del contrato de arrendamiento.
                    </li>
                    <li>
                      Verifica con la administración de la copropiedad los{' '}
                      <span className='font-bold'> horarios permitidos </span>
                      para la mudanza y el valor a entregaren calidad de depósito por
                      posibles daños.
                    </li>
                    <li>
                      Por seguridad cambia las guardas o claves de las cerraduras de
                      la puerta principal.
                    </li>
                    <li>
                      En caso de inmuebles nuevos deberás solicitar a las entidades
                      de servicios públicos la habilitación del servicio de gas. Así
                      mismo deberás notificar a Coninsa Ramón H. S.A. si no llegan
                      las facturas por los servicios de Acueducto, Alcantarillado,
                      Energía y Tasa de Aseo.
                    </li>
                    <li>
                      Si deseas terminar el contrato,{' '}
                      <span className='font-bold'>
                        {' '}
                        revisa las fechas de preaviso{' '}
                      </span>{' '}
                      para evitar que se te prorrogue.
                    </li>
                    <li>
                      Toda mejora o adaptación a realizarse en el inmueble deberá
                      contar con nuestra aprobación y la del propietario.
                    </li>
                    <li>
                      Infórmate con la administración sobre las normas de la
                      copropiedad.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </Tabs.Content>

          <Tabs.Content className='co-tabs-content' value='tab-4'>
            <div className='px-5 md:px-0 md:container'>
              <div className='mb-8 md:mb-10'>
                <h4 className='font-display text-[22px] md:text-3xl leading-6 md:leading-normal text-coninsa-blue-900 font-normal mb-3'>
                  ¿Cuáles son las tarifas de nuestros servicios?
                </h4>
                <p className='font-normal text-sm leading-[18px] md:leading-5 md:text-base'>
                  Conoce las tarifas de nuestro servicio de arrendamiento.
                </p>
              </div>

              <div className='px-0 md:px-40'>
                <div className='bloxk md:flex justify-between gap-4 mb-10'>
                  <RateCard
                    title='Medellín'
                    rate='20%'
                    desc='Por comisión o derechos de contrato'
                  >
                    <p className='font-bold text-center text-sm text-white'>
                      $40.000 de la papelería
                    </p>
                  </RateCard>

                  <RateCard
                    title='Bogotá'
                    rate='30%'
                    desc='Por comisión o derechos de contrato'
                  >
                    <p className='font-bold text-center text-sm text-white'>
                      Papelería incluida
                    </p>
                  </RateCard>

                  <RateCard
                    title='Barranquilla'
                    rate='60%'
                    desc='Por comisión o derechos de contrato'
                  >
                    <p className='font-bold text-center text-sm text-white'>
                      Papelería incluida
                    </p>
                  </RateCard>

                  <RateCard
                    title='Cartagena'
                    rate='20%'
                    desc='Por comisión o derechos de contrato'
                  >
                    <p className='font-bold text-center text-sm text-white'>
                      Papelería incluida
                    </p>
                  </RateCard>
                </div>

                <div className='p-5 bg-[#FBFBFB] mb-5 md:mb-8 rounded-lg'>
                  <p className='font-medium text-sm'>
                    Tanto el estudio de la solicitud como el negocio de arrendamiento
                    generan el cobro de una
                    <span className='font-bold'>
                      {' '}
                      comisión o derechos de contrato{' '}
                    </span>
                    por la gestión de consecución del inmueble, asesoría,
                    demostración y del cierre del negocio; estas tarifas están
                    definidas de acuerdo a la costumbre comercial de cada ciudad.
                  </p>
                </div>

                <div className=''>
                  <div className='flex items-center ml-6 px-8 md:px-36 md:ml-20 relative'>
                    <img
                      src={presentIcon}
                      className='absolute -left-4 md:left-14 z-10 w-24 h-24 md:w-auto md:h-auto'
                    />
                    <div className='rounded-lg shadow-[0px_3px_6px_#00000029] pl-16 pr-5 md:pr-7 py-3 md:py-5'>
                      <h3 className='font-bold md:text-xl text-coninsa-blue-900 leading-4'>
                        El estudio de tu solicitud es <br />
                        <span className='text-coninsa-green-500 text-[22px] md:text-4xl'>
                          ¡Totalmente Gratis!
                        </span>
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Tabs.Content>
        </Tabs.Root>
      </div>

      <div className='bg-coninsa-green-500 py-8 md:py-16'>
        <div className='px-5 md:px-0 md:container block md:grid grid-cols-2 gap-4'>
          <div>
            <h2 className='font-display font-medium text-2xl md:text-[44px] text-white uppercase'>
              Contáctanos
            </h2>
            <div className='border border-solid border-gray-400 my-4 md:my-5 w-32' />
            <p className='text-white text-xl md:text-3xl leading-7 mb-6 md:mb-0 md:leading-[44px] max-w-sm'>
              Déjanos tus datos y pronto un consultor inmobiliario se pondrá en
              contacto contigo, para ayudarte a encontrar el inmueble ideal.
            </p>
          </div>

          <div className='bg-white px-4 py-6 md:p-10'>
            <p className='font-bold mb-5 text-coninsa-gray-900 text-lg text-center'>
              Comunícate con nosotros
            </p>
            <ContactForm />
          </div>
        </div>
      </div>
    </div>
  )
}

export default LeasingPropertyPage
